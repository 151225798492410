







































































import { Activation } from "@/store/models/jwt";
import { JWTModule } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class PublicUserActivation extends Vue {
  payload = {} as Activation;
  title = "";
  message = "";
  caption = "";
  email = "";
  resend = false;

  created() {
    this.payload.uid = this.$route.params.uid;
    this.payload.token = this.$route.params.token;
    JWTModule.userActivation(this.payload)
      .then((response: any) => {
        this.title = "Activation Successful";
        this.message = "Your account was activated successfully.";
        this.caption = `Please <a href='/login'>login</a> to continue!`;
        if (response.message) {
          this.$snotify.success(response.message);
        }
      })
      .catch(() => {
        this.title = "Activation Error";
        this.message = "There was an error activating your account.";
        this.caption = "Please try resending activation mail!";
        this.resend = true;
      });
  }

  async onSubmit() {
    const isValid = await this.$validator.validateAll();
    if (isValid) {
      JWTModule.resendActivation({ email: this.email }).then(
        (response: any) => {
          if (response.message) {
            this.$snotify.success(response.message);
          }
        }
      );
    }
  }

  onLoginClick() {
    this.$bvModal.show("login");
  }
}
